import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'

interface saveReq {
  id?: string
  explain: string
  date: string
  staff_id?: string
  project_id: string
}
export type saveProject = saveReq //导出请求保存的数据结构

// 项目完结申请 添加接口
export function FinishSave(data: saveReq) {
  const url = '/api/project_finish/save'
  return cateringRequest.post(url, data)
}
